import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { ORG_BASE_OVERRIDES } from "@kuva/ui-helpers";

import { getBaseOrganization } from "~/common/actions/OrganizationActions";
import { setBaseOrgsHeaders } from "~/common/apis/api";
import { selectOrganizationState } from "~/common/selectors/organizations-selector";
import { OrganizationActions } from "~/common/store/slices/organizations/organizations-slice";

const useOrganization = () => {
  const dispatch = useDispatch();

  const organizationState = useSelector(selectOrganizationState, shallowEqual);
  const { loading, organizations, normalizedOrgs, selectedOrg, baseOrg } =
    organizationState;

  const setOrganizations = organizations => {
    dispatch(OrganizationActions.setOrganizations(organizations));
  };

  const setSelectedOrg = useCallback(
    org => {
      dispatch(OrganizationActions.setSelectedOrg(org));
    },
    [dispatch]
  );

  const handleOrganizationChange = useCallback(
    async org => {
      setSelectedOrg(org);
      const baseOrg = ORG_BASE_OVERRIDES[org.id]
        ? ORG_BASE_OVERRIDES[org.id].baseOrg
        : await getBaseOrganization(org.id);

      dispatch(OrganizationActions.setBaseOrg(baseOrg));
    },
    [setSelectedOrg, dispatch]
  );

  useEffect(() => {
    if (!selectedOrg) handleOrganizationChange(organizations[0]);
  }, [selectedOrg]);

  useEffect(() => {
    if (baseOrg) setBaseOrgsHeaders(baseOrg);
  }, [baseOrg]);

  return {
    setOrganizations,
    setSelectedOrg: handleOrganizationChange,
    organizations,
    selectedOrg,
    normalizedOrgs,
    baseOrg,
    loading
  };
};

export default useOrganization;
